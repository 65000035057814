import { mapState } from 'vuex';
import AddressDetail from '@/components/address/address-detail/index.vue';
import RetailProductItem from './components/retail-product-item/index.vue';
import { getMarketingOrderDetail, getAftermarketOrderDetail } from '@/service/enroll-activity/index';
import { getMarketingState } from '@/constants/marketing.js';
import { getAftermarketState } from '@/constants/aftermarket.js';
import { toExpress } from '@/utils/common.js';

export default {
    name: 'retail-order-detail',

    components: {
        RetailProductItem,
        AddressDetail,
    },

    computed: {
        isMarketing() {
            let state = this.$route.query.from;
            if (state === 'marketing') {
                return true;
            } else {
                return false;
            }
        },
    },

    filters: {
        getMarketingState(state) {
            return getMarketingState(state);
        },
        getAftermarketState(state) {
            return getAftermarketState(state);
        },
    },

    data() {
        return {
            order: {
                addressInfo: {},
            },
        };
    },

    methods: {
        onClickLink(item, e) {
            e.preventDefault();
        },
        toExpress(item, e) {
            item = { trackingNumber: item.trackNumber, com: item.com };
            toExpress(item, e);
        },
    },

    created() {
        let state = this.$route.query.from;
        if (state === 'marketing') {
            getMarketingOrderDetail({ code: this.$route.query.orderId }).then(res => {
                if (res) {
                    res.addressInfo = res.addressInfo ? res.addressInfo : {};
                    res.addressInfo.name = res.addressInfo.userName;
                    this.order = res;
                }
            });
        } else {
            getAftermarketOrderDetail({ code: this.$route.query.orderId }).then(res => {
                if (res) {
                    res.addressInfo = res.addressInfo ? res.addressInfo : {};
                    res.addressInfo.name = res.addressInfo.userName;
                    this.order = res;
                }
            });
        }
    },
};
